<template>
  <div>
    <el-row>
      <el-form v-model="condition" :inline="true" label-position="right" label-width="80px">
        <el-form-item label="唯一配置" prop="possessId" size="mini">
          <el-select v-model="condition.possessId" class="condition-select" i filterable placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in possessList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="咨询科目" prop="subjectIds" size="mini">

          <el-select v-model="condition.subjectIds" class="condition-select" filterable placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in subjectList" :key="item.id" :label="item.subjectName"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传时间" prop="uploadTime" size="mini">
          <el-date-picker v-model="condition.uploadTime" type="datetimerange" range-separator="至"
                          start-placeholder="上传日期开始" :default-time="['00:00:00', '23:59:59']"
                          end-placeholder="上传日期结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="doSearch">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-button-group :style="{marginBottom:'10px'}">
        <el-button v-permission="'crm:consultant:add'" type="primary" size="mini" @click="toAdd">添加</el-button>
        <el-button v-permission="'crm:consultant:allot'" type="primary" size="mini" @click="toAllot">分配
        </el-button>
        <el-button v-permission="'crm:consultant:import'" type="primary" size="mini" @click="importOppor">导入
        </el-button>
        <el-button v-permission="'crm:consultant:showTelephone'" type="primary" size="mini"
                   @click="showTelephone">
          查看手机
        </el-button>
        <el-button v-permission="'crm:consultant:updatePossessId'" type="primary" size="mini"
                   @click="toUpdatePossess">
          修改唯一配置
        </el-button>
        <el-button v-permission="'crm:import:fictitiousPhone'" type="primary" size="mini"
                   @click="openUploadFictitiousPhoneFile">
          导入虚拟手机号
        </el-button>
      </el-button-group>
    </el-row>
    <el-row>
      <el-table :data="opporTableData" style="width: 100%" stripe fit @select="selectRow" @select-all="selectAll"
                max-height="600"
                @selection-change="selectChange" v-loading="loading">
        <el-table-column align="center" type="selection" fixed="left"></el-table-column>
<!--        <el-table-column align="center" prop="subjectIds" label="咨询科目">-->
<!--            <template slot-scope="scope">-->
<!--                <div v-for="item in subjectList" v-if="item.id+'' === scope.row.subjectIds">{{item.subjectName}}</div>-->
<!--            </template>-->
<!--        </el-table-column>-->
        <el-table-column v-for="(col,index) in columns" :prop="col.name"
                         :label="col.label" width="150" align="center">
          <template slot-scope="scope">
              <div v-if="col.name === 'subjectId'">
                  <span v-for="item in subjectList" v-if="item.id+'' === scope.row.subjectIds">{{item.subjectName}}</span>
              </div>
            <span v-if="scope.row.tson && scope.row.tson[col.name]">{{ scope.row.tson[col.name] }}</span>
            <span v-else>{{ scope.row[col.name] }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="150" fixed="right" label="操作">
          <template slot-scope="slot">
            <el-button size="mini" v-permission="'crm:consultant:receive'" @click="drawOppor(slot.row.id)">领取</el-button>
            <el-button v-permission="'crm:consultant:allotOne'" size="mini" type="success" @click="allot(slot.row)">分配</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-sizes="[10, 20, 30, 200]"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
      </el-pagination>
    </el-row>
    <AllotOrUpdatePossess :opportunity-list="allotOrUpdateOrPossessObj.opportunityList"
                          :type="allotOrUpdateOrPossessObj.type"
                          :show-obj="allotOrUpdateOrPossessObj.showObj"
                          :choose-list="allotOrUpdateOrPossessObj.chooseList"
                          @reload="reload"
    >
    </AllotOrUpdatePossess>
    <ShowTelephone :opportunity-list="showTelephoneObj.opportunityList"
                   :show-obj="showTelephoneObj.showObj">

    </ShowTelephone>
    <ImportOpportunity :type="1"
                       :show-obj="importObj.showObj"
                       :token="importObj.token"
                       @getOpportunityList="getOpportunityList"
    >
    </ImportOpportunity>
    <TheAddOpportunity
            :pond-id="1"
        @reload="reload"
        :subject-list="subjectList"
        :possess-list="possessList"
        :adviser="0"
        :is-show-add-oppor="addOpporObj.showObj">
    </TheAddOpportunity>

    <el-dialog
        title="导入虚拟手机号"
        :visible.sync="uploadFictitiousPhoneFile"
        width="30%">
      <el-upload
              class="upload-demo"
              ref="upload"
              :headers="headers"
              action="/api/manage/crm/firstconsult/fictitiousPhone"
              :on-change="handleChangeFile"
              :on-success="handleUploadSuccess"
              :file-list="fileList"
              :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        <el-button style="margin-left: 10px;" size="small" type="warning" @click="downloadExcel">
          下载模板
        </el-button>
        <div slot="tip" class="el-upload__tip">
          <span>请上传.xlsx文件</span>
        </div>
      </el-upload>
      <div>
        <el-divider content-position="left">上传结果</el-divider>
        <div>
          <el-alert v-if="uploadFileTitle" :title="uploadFileTitle" type="success" :closable="false"/>
          <el-link v-for="(item, index) in uploadFileMessageList" :key="index" :type="styleTypeList[index % styleTypeList.length]">{{ item }}</el-link>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import AllotOrUpdatePossess from "./components/AllotOrUpdatePossess";
import * as api from "../../utils/api";
import ShowTelephone from "./components/ShowTelephone";
import ImportOpportunity from "./components/ImportOpportunity";
import * as searchApi from "@/api/system/searchDeploy";
import TheAddOpportunity from "@/components/opportunity/TheAddOpportunity";
import * as accountApi from "@/api/system/account";
import axios from "axios";

export default {
  name: "FirstConsult",
  components: {
    ShowTelephone,
    AllotOrUpdatePossess,
    ImportOpportunity,
    TheAddOpportunity
  },
  data() {
    return {
      styleTypeList: ["primary","success", "warning", "danger", "info"],
      opporTableData: [],
      allotOrUpdateOrPossessObj: {
        opportunityList: [],//分配或修改唯一配置的list
        chooseList: [],// 学习顾问列表，或者 唯一配置列表
        type: 1,
        showObj: {
          isShow: false
        }
      },
      showTelephoneObj: {
        opportunityList: [],//查看手机号
        showObj: {
          isShow: false
        }
      },
      addOpporObj: {
        showObj: {
          isShow: false
        },
        possessList: []
      },
      importObj: {
        showObj: {
          isShow: false
        },
        token: ""
      },
      possessList: [],
      subjectList: [],
      condition: {},
      page: {current: 1, size: 10, total: 0},
      multipleSelection: [],
      loading: true,
      columns: [],
      uploadFictitiousPhoneFile: false,
      fileList: [],
      uploadFileTitle: '',
      uploadFileMessageList: [],
      headers: {"version": localStorage.getItem("_version"),},
    };
  },
  methods: {
      keyboard(e) {
          if (e.keyCode === 13) {
              this.doSearch()
          }
      },
    getColumn: function () {
      searchApi.findByClassify("FIRST_TABLES").then(res => {
        if (res.data) {
          this.columns = res.data;
        }

      });
    },
    handleSizeChange(val) {
      this.page.size = val
      this.getOpportunityList(this.condition)
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.getOpportunityList(this.condition)
    },
    selectRow(select, row) {
      this.multipleSelection.push({id: row.id, telephone: row.telephone, kcuserId: row.kcuserId})
    },
    selectAll(select) {
      this.multipleSelection = select.map(res => {
        return {id: res.id, telephone: res.telephone, kcuserId: res.kcuserId}
      })
    },
    /**
     * 取消选择
     */
    selectChange(selection) {
      this.multipleSelection = selection.map(res => {
        return {id: res.id, telephone: res.telephone, kcuserId: res.kcuserId}
      })
    },
    /**
     * 获取唯一配置列表
     */
    getPossessList: function () {
      api.possessList().then(res => {
        if (res.success) {
          this.possessList = res.data
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("获取唯一配置列表失败");
      })

    },
    /**
     * 获取专业列表
     */
    getSubjectList: function () {
      api.subjectList().then(res => {
        if (res.success) {
          this.subjectList = res.data
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("获取专业列表失败");
      })
    },
    /**
     * 获取机会列表
     * @param params
     */
    getOpportunityList: function (params) {
      if(!params){
          params = {}
      }
      params['pondId'] = 1
      api.firstOpporListJson(this.page.current, this.page.size, params).then(res => {
        if (res.success) {
          let resEntity = res.data
          this.page.current = resEntity.current
          this.page.size = resEntity.size
          this.page.total = resEntity.total
          let r = resEntity.records
          for (let i in r) {
            let extra = r[i]['extra']
            if (extra) {
              let tson = JSON.parse(extra)
              r[i]["tson"] = tson
            }
          }
          this.opporTableData = r
          this.loading = false
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("获取首咨列表失败");
      })
    },
    dueConditionTime: function () {
      if (this.condition.uploadTime) {
        let uploadTime = this.condition.uploadTime
        let createTimeStart = uploadTime[0].format("yyyy-MM-dd HH:mm:ss")
        let createTimeEnd = uploadTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.condition.createTimeStart = createTimeStart
        this.condition.createTimeEnd = createTimeEnd
      } else {
        this.condition.createTimeStart = ""
        this.condition.createTimeEnd = ""
      }
      if (this.condition.allotTime) {
        let allotTime = this.condition.allotTime
        let vallotTimeStart = allotTime[0].format("yyyy-MM-dd HH:mm:ss")
        let vallotTimeEnd = allotTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.condition.vallotTimeStart = vallotTimeStart
        this.condition.vallotTimeEnd = vallotTimeEnd
      } else {
        this.condition.vallotTimeStart = ""
        this.condition.vallotTimeEnd = ""
      }
      if (this.condition.collectionTime) {
        let collectionTime = this.condition.collectionTime
        let collectionTimeStart = collectionTime[0].format("yyyy-MM-dd HH:mm:ss")
        let collectionTimeEnd = collectionTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.condition.collectionTimeStart = collectionTimeStart
        this.condition.collectionTimeEnd = collectionTimeEnd
      } else {
        this.condition.collectionTimeStart = ""
        this.condition.collectionTimeEnd = ""
      }

    },
    /**
     * 搜索
     */
    doSearch() {
      this.page.current = 1
      this.dueConditionTime()
      this.getOpportunityList(this.condition)
    },
    /**
     * 分配
     * @param row
     */
    allot(row) {
      this.allotOrUpdateOrPossessObj.opportunityList = [{id: row.id, telephone: row.telephone, kcuserId: row.kcuserId}]
      this.allotOrUpdateOrPossessObj.chooseList = this.adviserList
      this.allotOrUpdateOrPossessObj.type = 1
      this.allotOrUpdateOrPossessObj.showObj.isShow = true
    },
    /**
     * 分配
     */
    toAllot() {
      if (!this.multipleSelection.length) {
        this.$message.warning("当前未选择任何分配的机会")
        return
      }
      this.allotOrUpdateOrPossessObj.chooseList = this.adviserList
      this.allotOrUpdateOrPossessObj.opportunityList = this.multipleSelection
      this.allotOrUpdateOrPossessObj.type = 1
      this.allotOrUpdateOrPossessObj.showObj.isShow = true
    },
    /**
     * 修改唯一配置
     */
    toUpdatePossess() {
      if (!this.multipleSelection.length) {
        this.$message.warning("当前未选择任何修改的机会")
        return
      }
      this.allotOrUpdateOrPossessObj.chooseList = this.possessList
      this.allotOrUpdateOrPossessObj.opportunityList = this.multipleSelection
      this.allotOrUpdateOrPossessObj.type = 2
      this.allotOrUpdateOrPossessObj.showObj.isShow = true
    },
    /**
     * 导出领取记录
     */
    showTelephone() {
      if (!this.multipleSelection.length) {
        this.$message.warning("未选择要查看的手机号的机会")
        return
      }
      let idArr = this.multipleSelection.map(res => {
        return res.id
      })
      api.showTelephone(idArr).then(res => {
        if (res.success) {
          this.showTelephoneObj.opportunityList = res.data
          this.showTelephoneObj.showObj.isShow = true
        } else {
          this.$message.warning("查看手机号失败")
        }
      }).catch(error => {
        this.$message.error("查看手机号失败");
      })

    },
    /**
     * 领取机会
     */
    drawOppor(id) {
      api.draw(id, 3).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getOpportunityList(this.condition)
        } else {
          this.$message.error(res.msg)
        }
      }).catch(error => {
        this.$message.error("请求失败,请稍后重试!")
      })
    },
    /**
     * 添加
     */
    toAdd() {
      this.addOpporObj.showObj.isShow = true
    },
    /**
     * 导入机会
     */
    importOppor() {
      api.token().then(res => {
        this.importObj.token = res
        this.importObj.showObj.isShow = true
      })
    },
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    /**
     * 重新刷新页面
     * @param params
     */
    reload() {
      this.loading = true
      this.getOpportunityList(this.condition)
    },
    resetQuery() {
      this.condition = {}
      this.reload()
    },
    openUploadFictitiousPhoneFile() {
      this.fileList = []
      this.uploadFictitiousPhoneFile = true;
      this.uploadFileTitle = ''
      this.uploadFileMessageList = []
    },
    handleChangeFile(file, fileList) {
      this.fileList = [file];
      fileList = [file];
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleUploadSuccess(res, file, fileList) {
      if (res.success) {
        console.log(res.data)
        this.$message.success(res.msg)
        this.uploadFileTitle = `一共导入[${res.data.totalCount}]条数据, 成功[${res.data.successCount}]条, 失败[${res.data.totalCount - res.data.successCount}]条`
        this.uploadFileMessageList = res.data.msgList
      } else {
        this.$message.error(res.msg)
      }
    },
    /**
     * 下载Excel
     * @returns {Promise<void>}
     */
    async downloadExcel() {
      axios({
        method: "get",
        url: "/api/manage/crm/firstconsult/importTemplate",
        headers: {
          "version": localStorage.getItem("_version"),
        },
        responseType: 'blob',
      }).then(res => {
        const blob = new Blob([res.data]);
        const fileName = '模板.xlsx';
        const e_link = document.createElement('a')
        e_link.download = fileName;
        e_link.style.display = 'none';
        e_link.href = URL.createObjectURL(blob);
        document.body.appendChild(e_link);
        e_link.click();
        URL.revokeObjectURL(e_link.href); // 释放URL 对象
        document.body.removeChild(e_link);
      }).catch(error => {
        this.$message.error("导出模板失败");
      })
    },
  },
  mounted() {
    // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
    this.getPossessList()
    this.getSubjectList()
    this.getOpportunityList()
    this.getAdviserList()
    this.getColumn()
  }
}
</script>

<style scoped>

/deep/ .el-form-item {
  margin-bottom: 0;
  vertical-align: middle;
}
</style>
